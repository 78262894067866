import React from "react";
import ArticleStyle from "../../style/Article";
import { Flex, Box } from "../../style/Grid";
import ArticleContainer from "../../style/Article/ArticleContainer";
import { Text } from "../../style/Text";
import Link from "../Link";

import MagazineBlockSmall from "../MagazineBlock/MagazineBlockSmall";
import Icon from "../../style/Icon";
import { Heading } from "../../style/Heading";
import { ShareOnSocial } from "../../style/ShareOnSocial";

const Article = ({ content, next, prev, uri, similarPosts }) => {
  const articleURL = `${
    typeof location !== "undefined" ? location.origin : "https://kapitol.cz"
  }/magazin/${uri}`;
  return (
    <ArticleContainer>
      <ArticleStyle dangerouslySetInnerHTML={{ __html: content }} />
      <Box>
        <ShareOnSocial>
          <Box as="li">
            <Text
              color="headlinesSecondary"
              fontWeight={600}
              fontSize="16px"
              lineHeight="19px"
            >
              Sdílet
            </Text>
          </Box>
          <Box as={"li"}>
            <Flex
              alignItems="center"
              as={Link}
              href={`https://www.facebook.com/sharer/sharer.php?u=${articleURL}`}
              target={"_blank"}
            >
              <Icon icon="facebook-square" size={[33, null, 18, null, null]} />
            </Flex>
          </Box>
          <Box as={"li"}>
            <Flex
              alignItems="center"
              as={Link}
              href={`https://twitter.com/intent/tweet?text=Doporu%C4%8Duj%C3%AD%20%C4%8Dl%C3%A1nek%3A%20${articleURL}`}
              target={"_blank"}
            >
              <Icon icon="twitter-square" size={[33, null, 18, null, null]} />
            </Flex>
          </Box>
          <Box as={"li"}>
            <Flex
              alignItems="center"
              as={Link}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${articleURL}`}
              target={"_blank"}
            >
              <Icon icon="linkedin-square" size={[36, null, 20, null, null]} />
            </Flex>
          </Box>
        </ShareOnSocial>
        <Flex
          as="aside"
          justifyContent="space-between"
          mt={"20px"}
          mb={["48px", null, null, 5]}
        >
          {prev && (
            <Link href={prev}>
              <Flex alignItems="center">
                <Icon icon="arrow-previous" size={12} />
                Předchozí článek
              </Flex>
            </Link>
          )}
          {next && (
            <Link href={next} ml={"auto"}>
              <Flex alignItems="center">
                Následující článek
                <Icon icon="arrow-next" size={12} />
              </Flex>
            </Link>
          )}
        </Flex>
        {similarPosts && (
          <Flex flexDirection="column" mb={[0, null, null, 4]}>
            <Heading
              as="h3"
              fontWeight="300"
              display="flex"
              alignItems="center"
              fontSize={[4, null, 5]}
              lineHeight={0}
              mb={3}
              uppercase={true}
            >
              Související články
            </Heading>
            <MagazineBlockSmall
              flexDirection={["column", null, "row"]}
              posts={similarPosts}
            />
          </Flex>
        )}
      </Box>
    </ArticleContainer>
  );
};

export default Article;
