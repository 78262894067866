import styled from "styled-components";

const ShareOnSocial = styled.ul`
  display: flex;
  align-items: center;
`;

ShareOnSocial.defaultProps = {};

export { ShareOnSocial };
