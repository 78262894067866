import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Image } from "../../style/Image";
import { Heading } from "../../style/Heading";
import { Button } from "../../style/Button";
import { Wysiwyg } from "../../style/Wysiwyg";
import Link from "../Link";

const CareerBlock = ({ background, content: { title, cta, content }, image }) => (
  <Wrapper bg={background === "light" ? "panelSecondary" : "panelDark"}>
    <Container>
      <Flex flexDirection={["column", null, "row"]}>
        <Box width={1} maxWidth={590} mt={-4} mb={4} zIndex={1}>
          {image ? image : null}
        </Box>

        <Box pb={4} pt={[0, null, 4]} ml={[0, null, 4, 5, 5]}>
          <Heading
            as="h2"
            color={background === "light" ? "link" : "cta"}
            lineHeight={1}
            mb={3}
            fontSize={[5, null, null, 7]}
            uppercase={true}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Wysiwyg
            color={background === "light" ? "textGrey" : "negative"}
            mb={4}
            maxWidth={["none", null, 390]}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {cta && (
            <Button as={Link} variant={background === "light" ? "primary" : "secondary"} href={cta.url} target={cta.target} display="inline-block">
              {cta.title}
            </Button>
          )}
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default CareerBlock;
