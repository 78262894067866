import React from "react";
import { graphql } from "gatsby";
import moment from "moment";

import Link from "../components/Link";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import { Hero } from "../components/HeroV2";
import Article from "../components/Article";
import CareerBlock from "../components/CareerBlock";

import { Container, Wrapper } from "../style/Grid";
import { Button } from "../style/Button";
import {Image} from "../style/Image";

const MagazinPost = ({
  data: {
    wp: { post },
  },
  pageContext: { next, prev },
}) => {
  require("moment/locale/cs");

  const {
    id,
    title,
    date,
    excerpt,
    content,
    uri,
    categories: { nodes: categories },
    featuredImage,
    postProperties: { situationconnection },
  } = post;

  const getMainCategory = () => {
    if (situationconnection) {
      return situationconnection[0];
    } else {
      const exceptionIndex = categories.findIndex(category => category.slug === "tiskove-zpravy");
      return exceptionIndex !== -1 ? categories[exceptionIndex] : categories[0];
    }
  };

  const mainCategoryPage = getMainCategory();
  const mainCategory = mainCategoryPage && categories.find(category => category.slug === mainCategoryPage.slug);

  const similarPosts = mainCategory && mainCategory.posts.nodes.filter(post => post.id !== id);

  const bannerPost = situationconnection && {
    ...situationconnection[0]?.pageContent?.bannerPost,
    image: situationconnection[0]?.featuredImage?.node,
  };

  const banner = {
    heroBg: "image",
    title: title,
    date: moment(date).format("D. MMMM YYYY"),
  };

  const breadcrumbs = [
    {
      label: "Magazín",
      link: "magazin",
    },
  ];

  return (
    <Layout>
      <SEO title={title} />
      <Header variant="light" />

      <Hero
        breadcrumbs={breadcrumbs}
        image={featuredImage?.node}
        banner={{
          ...banner,
          cta: content.banner?.cta && (
            <Button as={Link} href={content.banner.cta.url} target={content.banner.cta.target} display="inline-block" mt={15}>
              {content.banner.cta.title}
            </Button>
          ),
        }}
        categories={categories}
      />
      <Wrapper>
        <Container flexDirection="column">
          <Article uri={uri} next={next} prev={prev} content={excerpt + content} similarPosts={similarPosts} />
        </Container>
        
        {bannerPost && bannerPost.content && bannerPost.image?.sourceUrl && (
          <CareerBlock background={"light"} content={bannerPost} image={<Image display="block" borderRadius={1} width={1} src={bannerPost.image.sourceUrl} /> } />
        )}
      </Wrapper>
    </Layout>
  );
};
export default MagazinPost;

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wp {
      post(id: $id) {
        id
        title
        content
        uri
        date
        excerpt
        featuredImage {
          node {
            heroImage: sourceUrl(size: MEDIUM_LARGE)
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        author {
          node {
            name
            slug
            avatar {
              url
            }
          }
        }
        tags {
          nodes {
            id
            name
            link
            slug
            uri
          }
        }
        categories {
          nodes {
            id
            name
            link
            slug
            uri
            posts(first: 3) {
              nodes {
                id
                title
                slug
                uri
                featuredImage {
                  node {
                    sourceUrl(size: THUMBNAIL)
                  }
                }
              }
            }
          }
        }
        postProperties {
          situationconnection {
            ... on wp_Page {
              id
              title
              slug
              uri
              featuredImage {
                node {
                  sourceUrl
                }
              }
              pageContent {
                bannerPost {
                  content
                  title
                  cta {
                    target
                    title
                    url
                  }
                  bannerpostimage {
                    sourceUrl(size: MEDIUM)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
