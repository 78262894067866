import React from "react";
import { Flex } from "../../style/Grid";
import { Image } from "../../style/Image";
import Link from "../Link";
import { MagazineBlockItemHeading } from "../../style/MagazineBlock";
import { MagazineBlockSmallImage } from "../../style/MagazineBlock/MagazineBlockSmallImage";
import { URLS } from "../../const/const";
import { htmlDecode } from "../../utils";

const MagazineBlockSmall = ({ sidebar, flexDirection, posts }) => (
  <Flex flexDirection={flexDirection} width={1}>
    {posts &&
      posts.map((post, index) => (
        <Link
          href={`${URLS.blog}${post.slug}/`}
          variant="smallBorderBottom"
          key={index}
          width={sidebar ? [1, null, posts.length === 3 ? "calc(33% - 20px)" : 1, 1, null] : [1, null, "calc(33% - 20px)", "calc(33% - 41px)"]}
          mr={index !== posts.length - 1 && [0, null, 40, 82]}
          mb={sidebar ? 3 : [3, null, null, 0]}
        >
          <Flex justifyContent="space-between">
            <MagazineBlockItemHeading as="h4" fontSize={2} height={["auto", "3em"]} lineHeight={1} width="65%">
              {htmlDecode(post.title)}
            </MagazineBlockItemHeading>
            <MagazineBlockSmallImage image={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}>
              <Image
                src={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                border={2}
                width="80px"
                height="80px"
                opacity="0"
              />
            </MagazineBlockSmallImage>
          </Flex>
        </Link>
      ))}
  </Flex>
);

export default MagazineBlockSmall;
